@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../../fonts/fontsCreditPlus/AvenirNextCyr-Light.eot');
  src: local('Avenir Next Cyr Light'), local('AvenirNextCyr-Light'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Light.woff') format('woff'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../../fonts/fontsCreditPlus/AvenirNextCyr-Demi.eot');
  src: local('Avenir Next Cyr Demi'), local('AvenirNextCyr-Demi'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Demi.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Demi.woff') format('woff'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Demi.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../../fonts/fontsCreditPlus/AvenirNextCyr-Medium.eot');
  src: local('Avenir Next Cyr Medium'), local('AvenirNextCyr-Medium'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Medium.woff') format('woff'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../../fonts/fontsCreditPlus/AvenirNextCyr-Regular.eot');
  src: local('Avenir Next Cyr Regular'), local('AvenirNextCyr-Regular'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Regular.woff') format('woff'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr Ultra';
  src: url('../../fonts/fontsCreditPlus/AvenirNextCyr-UltraLight.eot');
  src: local('Avenir Next Cyr Ultra Light'), local('AvenirNextCyr-UltraLight'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-UltraLight.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-UltraLight.woff')
      format('woff'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-UltraLight.ttf')
      format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../../fonts/fontsCreditPlus/AvenirNextCyr-Heavy.eot');
  src: local('Avenir Next Cyr Heavy'), local('AvenirNextCyr-Heavy'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Heavy.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Heavy.woff') format('woff'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Heavy.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../../fonts/fontsCreditPlus/AvenirNextCyr-Bold.eot');
  src: local('Avenir Next Cyr Bold'), local('AvenirNextCyr-Bold'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Bold.woff') format('woff'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../../fonts/fontsCreditPlus/AvenirNextCyr-Thin.eot');
  src: local('Avenir Next Cyr Thin'), local('AvenirNextCyr-Thin'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Thin.woff') format('woff'),
    url('../../fonts/fontsCreditPlus/AvenirNextCyr-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-transform: none;
  box-sizing: border-box;
  list-style: none;
  border-radius: 0;
  color: #003366;
  font-family: 'Avenir Next Cyr', sans-serif;
}

b,
strong {
  font-size: inherit;
  font-weight: 700;
  line-height: inherit;
  letter-spacing: inherit;
}

select,
input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
}

strong,
b {
  font-weight: 700;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

strong *,
b * {
  font-weight: 700;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

em,
i {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

em *,
i * {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

*:focus {
  outline: transparent;
}

.container_1170 {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}

.container-620 {
  position: relative;
  width: 90%;
  max-width: 620px;
  margin: 0 auto;
  min-height: 160px;
}

body {
  overflow-x: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body main {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

body footer {
  margin-top: auto;
  margin-bottom: 0;
}

input[type='submit'] {
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.nowrap {
  white-space: nowrap;
}

.btn-orange {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff8900;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;
  padding: 14px;
  max-width: 300px;
  transition: 0.4s;
  min-height: 50px;
}

.btn-orange:hover {
  background: #003366;
}

.btn-link {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #346da6;
  border-bottom: 1px solid #346da6;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 25px auto 0;
  transition: 0.4s;
}

.btn-link:hover {
  color: #ff8900;
  border-bottom-color: transparent;
}

.header {
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 51, 102, 0.21);
  padding: 12px 0;
  position: relative;
  z-index: 1000;
}

.header .container_1170 {
  display: flex;
  align-items: center;
}

.header__logo {
  height: 51px;
  display: block;
  margin-right: auto;
}

.header__logo img {
  display: block;
  width: auto;
  height: 100%;
}

.header__phone {
  &--left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.header__phone a {
  font-weight: 450;
  font-size: 14px;
  line-height: 17px;
  transition: 0.4s;
  border-bottom: 1px solid #003366;
}

.header__phone a:hover {
  color: #ff8900;
  border-bottom-color: transparent;
}

.header__phone span {
  display: block;
  padding-top: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.header__lang {
  margin-left: 40px;
}

.header__lang ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header__lang li {
  border: none;
  border-bottom: 1px solid #ccd5df;
}

.header__lang li:last-child {
  border-bottom: none;
}

.header__lang li.active a {
  font-weight: 500;
  pointer-events: none;
}

.header__lang a {
  display: block;
  padding: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  transition: 0.4s;
}

.header__lang a:hover {
  color: #ff8900;
}

.footer {
  background: #13325d;
  padding: 40px 0 10px;
}

.footer .container_1170 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.footer__left {
  width: calc(59% - 25px);
  max-width: 565px;
}

.footer__left h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 30px;
}

.footer__contacts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer__contacts > * {
  margin-bottom: 40px;
}

.footer__contacts .icon-box {
  width: calc(33.33% - 20px);
  display: flex;
  align-items: flex-start;
}

.footer__contacts .icon-box svg {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.footer__contacts .icon-box .info {
  width: calc(100% - 35px);
}

.footer__contacts .icon-box .info a {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: white;
  border-bottom: 1px solid white;
  transition: 0.4s;
}

.footer__contacts .icon-box .info a:hover {
  border-bottom-color: transparent;
  color: #ff8900;
}

.footer__contacts .icon-box .info span {
  display: block;
  padding-top: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #7d92b2;
}

.footer__contacts .icon-box .info p {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
}

.footer__contacts .soc-box {
  display: flex;
  align-items: center;
  width: calc(66.66% - 10px);
}

.footer__contacts .soc-box p {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  margin-right: auto;
}

.footer__contacts .soc-box ul {
  display: flex;
  align-items: center;
}

.footer__contacts .soc-box li {
  margin-right: 16px;
}

.footer__contacts .soc-box li:last-child {
  margin-right: 0;
}

.footer__contacts .soc-box a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1c4073;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  padding: 8px;
  transition: 0.4s;
}

.footer__contacts .soc-box a svg {
  display: block;
  width: 100%;
  height: 100%;
}

.footer__contacts .soc-box a:hover {
  background: #284a7e;
}

.footer__right {
  width: calc(41% - 25px);
  max-width: 370px;
  margin-bottom: 40px;
}

.footer__right p {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #7d92b2;
  margin-bottom: 30px;
}

.footer__right p:last-child {
  margin-bottom: 0;
}

.blockForm {
  padding: 40px 0;
}

.blockForm .container-620 {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 40px 125px 50px;
}

.blockForm h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 30px;
}

.blockForm__top {
  margin-bottom: 30px;
}

.blockForm__top .row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.blockForm__top .row:last-child {
  margin-bottom: 0;
}

.blockForm__top .row p {
  font-weight: 450;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
}

.blockForm__top .row b {
  font-weight: 450;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
}

.blockForm__top .row b:before {
  content: '';
  display: block;
  width: 100%;
  border-bottom: 1px dashed #7b9ab8;
  margin: 0 5px 3px;
}

.blockForm__form .sub-form-info {
  background: #f3f9ff;
  border-radius: 8px 8px 0 0;
  padding: 10px 15px 20px;
  margin-bottom: -10px;
}

.blockForm__form .sub-form-info p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #7b9ab8;
}

.blockForm__form .blueForm {
  background: #e7f2ff;
  border-radius: 8px;
  padding: 15px 15px 20px;
  margin-bottom: 30px;
}

.blockForm__form .blueForm > * {
  margin-bottom: 15px;
}

.blockForm__form .blueForm > *:last-child {
  margin-bottom: 0;
}

.blockForm__form .input-row {
  display: flex;
}

.blockForm__form .input-row > * {
  margin-right: 10px;
}

.blockForm__form .input-row > *:last-child {
  margin-right: 0;
}

.blockForm__form .input-box {
  width: 100%;
  position: relative;
  &__icon::before {
    top: 30px;
    left: 5px;
    content: '';
    position: absolute;
    height: 30px;
    width: 30px;
    background-image: url('./../../images/creditPlus/card-input.svg');
  }
  &__inactive .input-wrap__icon::before {
    background-image: url('./../../images/creditPlus/card-input_inactive.svg');
  }
}

.input-wrap {
  position: relative;
  &.input-wrap__icon::before {
    top: 3px;
    left: 5px;
    content: '';
    position: absolute;
    height: 30px;
    width: 30px;
    background-image: url('./../../images/creditPlus/card-input.svg');
  }
}

.blockForm__form .input-box label {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}

.blockForm__form .input-box label .info-btn {
  width: 12px;
  height: 12px;
  background: #ccd5df;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 8.41837px;
  line-height: 10px;
  color: #ffffff;
  margin-left: 5px;
  position: relative;
}

.blockForm__form .input-box label .info-btn:hover .info-window {
  opacity: 1;
}

.blockForm__form .input-box label .info-btn .info-window {
  position: absolute;
  z-index: 2;
  left: 50%;
  bottom: calc(100% + 10px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #ffffff;
  border-radius: 4px;
  width: 180px;
  padding: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 11px rgba(1, 50, 100, 0.35);
  font-weight: 450;
  font-size: 12px;
  line-height: 150%;
  text-align: left;
  opacity: 0;
  transition: 0.4s;
  pointer-events: none;
}

.blockForm__form .input-box label .info-btn .info-window svg {
  display: block;
  width: 40px;
  min-width: 40px;
  height: 24px;
  margin-left: 5px;
}

.blockForm__form .input-box label .info-btn .info-window:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid white;
  position: absolute;
  left: 50%;
  top: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.blockForm__form .input-box input {
  background: #ffffff;
  border: 1px solid #003366;
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 36px;
  padding: 0 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.blockForm__form .input-box input::-webkit-input-placeholder {
  color: #ccd5df;
}

.blockForm__form .input-box input::-moz-placeholder {
  color: #ccd5df;
}

.blockForm__form .input-box input::-ms-input-placeholder {
  color: #ccd5df;
}

.blockForm__form .input-box input::placeholder {
  color: #ccd5df;
}

.blockForm__form .input-box .bank-logo {
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: auto;
  max-height: 17px;
  max-width: 85px;
  -o-object-fit: contain;
  object-fit: contain;
}

.blockForm__form .input-box .hider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #e0e9f2;
  border-radius: 3px;
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  transition: 0.4s;
}

.blockForm__form .input-box .hider:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -45%) rotate(-45deg);
  transform: translate(-50%, -45%) rotate(-45deg);
  width: 17px;
  height: 1px;
  background: #7b9ab8;
  transition: 0.4s;
}

.blockForm__form .input-box .hider:hover {
  background: #e7f2ff;
}

.blockForm__form .input-box .hider.nohide:before {
  opacity: 0;
}

.blockForm__form .btn-orange {
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
}

.blockForm__form .btn-link {
  margin: 25px auto 0;
}

.blockForm__logotypes {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blockForm__logotypes img {
  display: block;
  width: auto;
  height: 26px;
  margin: 0 15px;
}

@media screen and (min-width: 661px) {
  .footer__contacts .icon-box:nth-child(3n),
  .footer__contacts .icon-box:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    padding-bottom: 50px;
  }

  .footer .container_1170 {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer__left {
    width: 100%;
  }

  .footer__right {
    width: 100%;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 7px 0;
  }

  .header__logo {
    height: 33px;
  }
}

@media screen and (max-width: 700px) {
  .blockForm .container-620 {
    padding: 40px;
  }
}

@media screen and (max-width: 660px) {
  .footer__contacts .icon-box {
    width: calc(50% - 15px);
  }

  .footer__contacts .icon-box:nth-child(1) {
    order: 1;
  }

  .footer__contacts .icon-box:nth-child(2) {
    order: 2;
  }

  .footer__contacts .icon-box:nth-child(3) {
    order: 3;
  }

  .footer__contacts .icon-box:nth-child(5) {
    order: 4;
  }

  .footer__contacts .soc-box {
    order: 5;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .blockForm {
    padding: 30px 0 40px;
    background-color: #fff;
  }

  .blockForm .container-620 {
    padding: 0;
    box-shadow: none;
  }

  .blockForm__top {
    margin-bottom: 20px;
  }

  .blockForm__top .row p {
    font-size: 12px;
    line-height: 15px;
  }

  .blockForm__top .row b {
    font-size: 12px;
    line-height: 15px;
  }

  .blockForm__form .sub-form-info {
    padding: 10px 10px 20px;
  }

  .blockForm__form .btn-orange {
    max-width: 280px;
  }
}

@media screen and (max-width: 450px) {
  .footer__left h2 {
    font-size: 14px;
  }

  .footer__contacts .icon-box {
    width: 100%;
    margin: 0 0 25px 0;
  }

  .footer__right p {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 340px) {
  .header__lang {
    margin-left: 25px;
  }
}

.input-box .error-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  color: #ea1717;
  padding-top: 5px;
  display: none;
}

.input-box {
  &.invalid input {
    border-color: #ea1717;
  }
  &.valid input {
    border-color: rgb(0, 255, 0);
  }

  &__inactive label {
    color: #7d92b2;
  }

  &__inactive input {
    color: #7d92b2;
    border-color: #b7c3d6 !important;
  }
}

.input-box {
  &.invalid .error-text {
    display: block;
  }
  &.invalid .input-wrap__icon::after {
    top: 7px;
    right: 5px;
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    background-image: url('./../../images/creditPlus/explanation-mark-error.png');
    background-repeat: no-repeat;
  }
}

.input-mask {
  &__cards {
    padding-left: 40px !important;
  }
}

.style-bold {
  color: inherit;
  font-weight: 550;
}

.spinner-c-plus {
  & svg {
    fill: rgba(107, 169, 107, 0.6);
  }
  & svg path:first-child {
    display: none;
  }
  &--centred {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
