.cover-form {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(131, 127, 127, 0.3);
  &__k7 {
    background-color: rgb(247, 241, 241, 0.2);
  }
}
