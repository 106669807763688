.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.7);
  height: 100vh;
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.modal-content {
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  z-index: 1000;
}
