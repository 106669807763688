body,
html,
#root {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1 0 auto;
}

.header-container {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.header-content {
  width: 100%;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-company-name {
  color: rgba(41, 48, 86, 1);
  font-weight: bold;
  font-size: 25px;
}
.header-logo {
  display: flex;
  gap: 10px;
  align-items: center;
}
.header-phone {
  color: rgb(52, 64, 84);
  font-size: 15px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: center;
  display: flex;
  gap: 10px;
  align-items: center;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgba(245, 246, 249, 1);
}

.footer-container {
  width: 100%;
  background-color: rgba(78, 91, 166, 1);
  color: #fff;
  text-align: center;
  height: 80px;
  padding: 10px 0;
  border-top: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-container {
  width: 100%;
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 24px;
  margin: 12px 0;
  box-sizing: border-box;
  border: 1px solid rgb(242, 244, 247);
  border-radius: 20px;
  box-shadow: 0px 4px 5px 0px rgba(41, 48, 86, 0.05);
  background: rgb(252, 252, 253);
}

.card-block {
  position: relative;
  margin-top: 30px;
  left: 100px;
}
.card-container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

.card2 {
  width: 432.88px;
  height: 273.93px;
  box-sizing: border-box;
  border-radius: 30px;
  box-shadow: 0px 4px 32px 0px rgba(0, 16, 61, 0.16);
  background: linear-gradient(
    135.87deg,
    rgb(255, 231, 147) 0%,
    rgb(249, 252, 110) 98.118%
  );
}
.card {
  width: 433px;
  height: 291px;
  border-radius: 30px;
  box-shadow: 0px 4px 32px 0px rgba(0, 16, 61, 0.16);
  background: linear-gradient(
    135.87deg,
    rgb(255, 231, 147) 0%,
    rgb(249, 252, 110) 98.186%
  );
  position: absolute;
  right: 235px;
  bottom: 15px;
}
.label {
  color: rgb(51, 40, 3);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.card-content-suncredit {
  box-sizing: border-box;
  border-radius: 30px;
  padding: 50px;
}
.card2-bgc {
  height: 50px;
  background-color: rgba(102, 112, 133, 1);
  margin-top: 30px;
}
.cvv-block {
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;
  width: 160px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  gap: 10px;
}
.btn-block {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.btn-sunCredit {
  border-radius: 40px;
  background: rgb(255, 221, 28);
  width: 432px;
  &:hover {
    background: rgb(212, 187, 41);
  }
  height: 64px;
  padding: 0px 32px 0px 32px;
  margin: 10px 0px;
  color: rgb(51, 40, 3);
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.invalid {
  background: rgb(228, 231, 236);
  &:hover {
    background: rgb(228, 231, 236);
  }
}
.protocol-sun {
  color: rgb(152, 162, 179);
  font-size: 13px;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0%;
  text-align: center;
  margin-top: 20px;
}
.label-placeholder {
  color: rgb(51, 40, 3);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0%;
  text-align: left;
}

.payment-details-container {
  text-align: center;
  padding: 20px;
  width: 676px;
}

.payment-details-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.payment-details-box {
  box-sizing: border-box;
  border: 4px solid rgb(252, 252, 253);
  border-radius: 20px;
  box-shadow: -4px 13px 37px 0px rgba(95, 214, 252, 0.15);
  background: rgb(255, 255, 255);
  padding: 10px;
}

.payment-details-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.payment-details-row:last-child {
  border-bottom: none;
}

.payment-details-label {
  color: rgb(29, 41, 57);
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0%;
  text-align: left;
}

.payment-details-value {
  color: rgb(71, 84, 103);
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0%;
  text-align: left;
  word-break: break-word;
}

.row-bg {
  border-radius: 10px;
  background: rgb(248, 249, 252);
}

.sunCredit-btn {
  width: 400px;
  margin: 0 auto;
}
.sucredit-user-aggre-block {
  width: 430px;
}
.sunCredit-input {
  height: 64px !important;
  border-radius: 16px!important;
}
.sunCredit-input::placeholder {
  color: rgb(152, 162, 179);
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0%;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .form-container {
    padding: 20px;
    padding-bottom: 150px;
  }
  .card-container {
    flex-direction: column;
    align-items: center;
  }
  .card {
    right: 0;
  }
  .card-block {
    left: 0;
  }
  .card {
    width: 300px;
    height: auto;
    position: relative;
    margin-bottom: 20px;
    box-shadow: 0px 4px 15px 0px rgba(0, 16, 61, 0.1);
    z-index: 1000;
  }

  .btn-block {
    position: relative;
    top: -125px;
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 20px;
  }
  .pay-block {
    position: relative;
    top: -132px;
  }

  .sunCredit-btn {
    width: 290px;
    margin: 0 auto;
  }
  .card2 {
    width: 300px;
    position: relative;
    bottom: 162px;
  }
  .card-content {
    padding: 20px;
    margin-top: 20px;
  }
  .btn-sunCredit {
    width: 288px;
    position: relative;
    bottom: 130px;
    margin: 0 auto;
    margin-bottom: 18px;
  }
  .accordion {
    display: flex;
    justify-content: center;
    width: 300px;
  }

  .label-placeholder {
    width: 137px;
  }
  .payment-details-container {
    width: 337px;
  }

  .forn-suncredit {
    max-height: 587px;
  }

  .protocol-sun {
    position: relative;
    top: -132px;
    color: rgb(152, 162, 179);
    font-size: 13px;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 0%;
    text-align: center;
  }
  .sucredit-user-aggre-block {
    position: relative;
    top: -138px;
    width: 322px;
    margin: 0 auto;
  }
  .card-content-suncredit {
    padding: 20px;
  }
  .card2-bgc {
    background: linear-gradient(
      135.87deg,
      rgb(255, 231, 147) 0%,
      rgb(249, 252, 110) 98.186%
    );
  }

  .cvv-block {
    margin-top: 90px;
    display: flex;
    bottom: 20px;
    right: 20px;
    width: 100%;
    align-items: center;
    flex-direction: row;
  }
}
