@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Commissioner', sans-serif;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1 0 auto;
}

.main {
  background-image: url('../../images/avansCredit/bg2.svg');
  background-size: cover;
  background-position: center;

  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgb(245, 246, 249);
}


.header {
  background: #f6eef9;
  height: 66px;
}

.footer {
  height: 88px;
  background: rgba(46, 47, 64, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.content {
  max-width: 970px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.accordion {
  margin-bottom: 30px;
  max-width: 640px;
}
.accordionText {
  color: rgb(255, 39, 39);
  font-family: Commissioner;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.accordionWarning {
  color: rgb(255, 39, 39);
  font-family: Commissioner;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0%;
  text-align: left;
  max-height: 62px;
  overflow-y: auto;
}

.logo {
  font-size: 25px;
  font-weight: 800;
  line-height: 24.46px;
  text-align: center;
  font-family: Commissioner;
  color: rgb(27, 4, 94);
  display: flex;
  align-items: center;
  gap: 5px;
}

.posRelative {
  position: relative;
}
.posAbsolute {
  position: absolute;
  top: 15px;
  right: 13px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: rgb(74, 44, 200);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  cursor: pointer;
}

.phoneNumber {
  font-size: 20px;
  font-weight: 800;
  line-height: 24.46px;
  text-align: center;
  font-family: Commissioner;
  color: #2e2f40;
}
.phoneText {
  font-family: Commissioner;
  font-size: 16px;
  font-weight: 300;
  line-height: 14.66px;
  text-align: center;
  color: #2e2f40;
}
.phone {
  display: flex;
  align-items: center;
  gap: 15px;
}

.form {
  padding: 30px;
  border: 2px solid #b133fe;
  border-radius: 10px;
  width: 640px;
}
.formCardText {
  font-family: Roboto;
  font-size: 21px;
  font-weight: 700;
  line-height: 24.61px;
  text-align: center;
  color: #2e2f40;
}
.formCardTextDescr {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: center;
  color: #2e2f40;
  margin-top: 10px;
}
/////////////////////

.blockForm h1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 15px;
  color: #130e21;
}

.blockForm__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 25px;
  &.blockForm__top--no-mb {
    margin-bottom: 0;
  }
  &.blockForm__top--xs-mb {
    margin-bottom: 10px;
  }
}

.blockForm__form > * {
  margin-bottom: 30px;
}

.blockForm .inout_row {
  display: flex;
  position: relative;
  gap: 20px;
}

.blockForm .inout_row > *:last-child {
  margin-right: 0;
}

.blockForm .input_box {
  width: 100%;
  position: relative;
}

.blockForm .input_box input {
  display: block;
  width: 100%;
  height: 45px;
  padding: 0 15px;
  background: #ffffff;
  border: 1px solid #bac3d5;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  border: 2px solid transparent;
}
// .blockForm .input_box input:focus {
//   border: 2px solid #ffb400;
// }

.blockForm .input_box label {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #697395;
  pointer-events: none;
  position: absolute;
  left: 10px;
  top: 23px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 2px 5px;
  transition: 0.4s;
  border-radius: 3px;
}

.blockForm .input_box input:focus + label,
.blockForm .input_box.not_empty label {
  top: 1px;
  left: 10px !important;
  font-size: 13px;
  background: white;
}

.blockForm .input_box.input_box_icon .icon {
  position: absolute;
  left: 9px;
  top: 22px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background: #f7f7fa;
  border-radius: 3px;
  padding: 6px;
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blockForm .input_box.input_box_icon .icon svg {
  display: block;
  width: 100%;
  height: 100%;
}

.input_box .error_text {
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  color: #ea1717;
  padding-top: 5px;
  display: none;
}
.input_box .error_img {
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  color: black;
  padding-top: 5px;
  position: absolute;
  top: 9px;
  right: 15px;
}
.input_box .success_img {
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  color: black;
  padding-top: 5px;
  position: absolute;
  top: 6px;
  right: 15px;
}
.input_box .success_img_sunCredit {
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  color: black;
  padding-top: 5px;
  position: absolute;
  top: 17px;
  right: 15px;
}

.input_box {
  &.invalid input {
    border-color: #ea1717;
  }
  &.valid input {
    border-color: rgb(0, 255, 0);
  }
}

.input_box.invalid .error_text {
  display: block;
}
.btnBlock {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 0 auto;
}
.btn {
  width: 400px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-radius: 25px;
  box-shadow: 0px 4px 8px 0px rgba(0, 176, 36, 0.43);
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  background: rgb(0, 185, 45);
}

.container {
  position: relative;
  margin-top: 40px;
  left: 28px;
}

.card_block,
.card_block2 {
  position: relative;
  width: 356px;
  min-height: 214.5px;
  padding: 42px 24px 8px 24px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 25px;
  box-shadow: 0px 10px 17px 0px rgba(158, 140, 238, 0.66);
  background: linear-gradient(
      264.96deg,
      rgba(40, 47, 227, 0.75) -17.396%,
      rgba(174, 132, 235, 0.75) 15.757%
    ),
    rgb(255, 255, 255);
}

.card_block2 {
  position: absolute;
  left: 164px;
  bottom: 26px;
  padding: 0;
  min-height: 221.5px;
}
.card_block2_border {
  height: 43px;
  margin-top: 20px;
  background: rgb(133, 101, 186);
}

.blockForm_label {
  color: rgb(255, 255, 255);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0%;
  text-align: left;
}
.card_block2_cvv {
  position: absolute;
  width: 110px;
  flex-direction: row;
  right: 25px;
  margin-top: 24px;
}
.blockForm_label_placeholder {
  color: rgb(46, 47, 64);
  font-family: Roboto;
  font-size: 9px;
  font-weight: 400;
  line-height: 140.5%;
  letter-spacing: 0%;
  text-align: left;
}
.expiryBlock {
  margin-top: 10px;
}
.dataProtocol {
  display: flex;
  justify-content: center;
  color: rgb(122, 122, 122);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0%;
  text-align: center;
  margin-top: 20px;
}
.avans_user_aggre_block {
  width: 400px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .form {
    padding-bottom: 400px;
  }
  .accordion {
    width: 346px;
  }
  .accordionBlock{
    display: flex;
    justify-content: center;
    width: 350px;
  }
  .header {
    padding: 0 10px;
  }
  .phoneNumber {
    font-size: 14px;
    font-weight: 800;
    line-height: 24.46px;
    text-align: center;
    font-family: Commissioner;
    color: #2e2f40;
  }
  .phoneText {
    font-family: Commissioner;
    font-size: 14px;
    font-weight: 300;
    line-height: 14.66px;
    text-align: center;
    color: #2e2f40;
  }
  .container {
    display: flex;
    justify-content: center;
  }

  .card_block {
    position: absolute;
    left: -41px;
    max-width: 312px;
  }
  .form {
    max-width: 346px;
    margin: 20px;
    min-height: 655px;
    margin-top: 0;
  }
  .card_block2 {
    left: -40px;
    top: 94px;
    max-width: 312px;
    min-height: 233.5px;
  }
  .btnBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 340px;
    width: 293px;
  }
  .blockForm_label_placeholder {
    position: absolute;
    left: 137px;
    bottom: 5px;
    width: 153px;
  }
  .card_block2_cvv {
    position: absolute;
    width: 126px;
    flex-direction: row;
    left: 25px;
    margin-top: 67px;
  }
  .btn {
    width: 305px;
  }
  .avans_user_aggre_block {
    width: 300px;
    margin: 0 auto;
    position: relative;
    top: 335px;
  }
}
