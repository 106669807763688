.scrollableContainer {
  position: relative;
  width: 676px;
  height: 105px;
  overflow: auto;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 20px;

  box-shadow: 0px 4px 7px 0px rgba(180, 146, 0, 0.12);
  backdrop-filter: blur(25px);
  background: rgba(255, 255, 255, 0.96);
  margin-bottom: 30px;
}
.scrollableContainer::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  pointer-events: none;
}

.textarea {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 8px;
  box-sizing: border-box;
  resize: none;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 18px;
  color: rgb(251, 135, 136);
  font-family: Mulish, sans-serif;
  text-align: left;
}

.textarea::-webkit-scrollbar {
  width: 4px;
}

.textarea::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0.3);
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-thumb {
  background: linear-gradient(
    135.87deg,
    rgb(255, 231, 147) 0%,
    rgb(249, 252, 110) 98.118%
  );
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-thumb:hover {
  background: rgb(234, 236, 245);
}
