.scrollableContainer {
  position: relative;
  width: 640px;
  height: 105px;
  overflow: auto;
  border: 1px solid rgb(129, 216, 208);
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgb(226, 248, 255);
  background: rgb(255, 255, 255);
}
.scrollableContainer::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  pointer-events: none;
}

.textarea {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 8px;
  box-sizing: border-box;
  resize: none;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 18px;
  color: rgb(251, 135, 136);
  font-family: Mulish, sans-serif;
  text-align: left;
}

.textarea::-webkit-scrollbar {
  width: 4px;
}

.textarea::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0.3);
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-thumb {
  background: rgba(66, 191, 184, 1);
  border-radius: 4px;
}

.textarea::-webkit-scrollbar-thumb:hover {
  background: rgba(44, 160, 150, 1);
}