.modal-check {
  width: 350px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(103, 87, 156, 0.25);
  border-radius: 10px;
  padding: 20px;
  z-index: 1000;
  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #515151;
    margin-bottom: 20px;
  }
}
