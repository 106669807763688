@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('../../fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('../../fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('../../fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url('../../fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-transform: none;
  box-sizing: border-box;
  list-style: none;
  border-radius: 0;
  font-family: 'Montserrat', sans-serif;
  line-height: 1;
}

select,
input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
}

*:focus {
  outline: transparent;
}

input[type='submit'] {
  border: none;
  cursor: pointer;
}

input[type='submit']:disabled {
  background-color: #c7c7c7;
}

b,
strong {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: 500;
}

i,
em {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: inherit;
}

body,
#root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  position: relative;
}

body footer {
  margin-top: auto;
}

button {
  cursor: pointer;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  width: 90%;
}

.container-s {
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  width: 90%;
  min-height: 250px;
}

.header {
  background: #ffffff;
  padding: 15px 0;
  position: relative;
  z-index: 3;
}

.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__logo {
  display: block;
}

.header__logo img {
  display: block;
  height: 100%;
  width: auto;
}

.header__phone {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  transition: 0.4s;
  border-bottom: 1px solid transparent;
}

.footer {
  padding: 30px 0;
}

.footer * {
  color: white;
}

.footer .container {
  display: flex;
  justify-content: space-between;
}

.footer__left {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.footer__ttl {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
}

.footer__list {
  display: flex;
}

.footer__box {
  display: flex;
  align-items: flex-start;
  margin-right: 60px;
  &-wrap {
    display: flex;
  }
}

.footer__box.footer__box-phone {
  flex-direction: column;
  p {
    font-size: 10px;
  }
}

.footer__box svg {
  display: block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.footer__box p,
.footer__box a {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
}

.footer__right {
  max-width: 485px;
}

.footer__right p {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
}

.popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(51, 51, 51, 0.67);
  z-index: 10;
}

.popup__container {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 40px 30px 30px;
  width: 90%;
  max-width: 910px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(103, 87, 156, 0.25);
  border-radius: 10px;
}

.popup__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #515151;
  margin-bottom: 25px;
}

.popup__shadow {
  position: relative;
  padding: 15px 20px 15px 20px;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
}

.popup__shadow:before {
  content: '';
  width: calc(100% - 24px);
  height: 90px;
  position: absolute;
  left: 1px;
  bottom: 1px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 27.27%,
    rgba(255, 255, 255, 0.34) 62.5%,
    #ffffff 100%
  );
  border-radius: 9px;
}

.popup__scroll {
  max-height: 300px;
  overflow: auto;
  padding: 0 10px 30px 0;
}

.popup__scroll::-webkit-scrollbar {
  width: 3px;
}

.popup__scroll::-webkit-scrollbar-track {
  background: transparent;
}

.popup__scroll::-webkit-scrollbar-thumb {
  background: #212121;
  border-radius: 9px;
}

.popup__scroll::-webkit-scrollbar-thumb:hover {
  background: #212121;
}

.popup__scroll > * {
  margin-bottom: 15px;
}

.popup__scroll > *:last-child {
  margin-bottom: 0;
}

.popup__scroll p,
.popup__scroll li,
.popup__scroll span,
.popup__scroll div,
.popup__scroll font {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

.popup .btn {
  margin-top: 30px;
}

.popup .exit-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.popup .exit-btn:before {
  content: '';
  width: 25px;
  height: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  background: #515151;
}

.popup .exit-btn:after {
  content: '';
  width: 25px;
  height: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  background: #515151;
}

.alert {
  padding: 20px 0 0;
}

.alert.error .container-s {
  background: #ea1717;
}

.alert .container-s {
  border-radius: 6px;
  padding: 10px 35px 10px 10px;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.alert svg {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.alert p {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

.alert .exit-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.alert .exit-btn:before {
  content: '';
  width: 18px;
  height: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
  background: #fff;
}

.alert .exit-btn:after {
  content: '';
  width: 18px;
  height: 2px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  background: #fff;
}

.cardProof {
  padding: 40px 0;
}

.cardProof {
  & .container-s {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(103, 87, 156, 0.25);
    border-radius: 10px;
    padding: 50px 200px;
  }
  & .container-s--min-pd {
    padding: 50px 150px;
  }
}

.cardProof h1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #515151;
  margin-bottom: 30px;
}

.cardProof .input-row {
  display: flex;
  justify-content: space-between;
}

.cardProof .input-row .input-box:nth-child(1) {
  width: calc(53.5% - 10px);
}

.cardProof .input-row .input-box:nth-child(2) {
  width: calc(46.5% - 10px);
}

.cardProof .formBottom {
  padding-top: 30px;
}

.cardProof .formBottom p {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #a7adb5;
}

.cardProof .formBottom p a {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #a7adb5;
  text-decoration: underline;
  transition: 0.4s;
}

.cardProof .formBottom__logo {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.cardProof .formBottom__logo img {
  display: block;
  flex-grow: 1;
  margin-right: 20px;
}

.cardProof .formBottom__logo img:last-child {
  margin-right: 0;
}

.cardProof__list {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e7e7e7;
  &--no_border {
    border: none;
  }
}

.cardProof__list-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  &--huge-pd {
    padding: 20px 5px;
  }
}

.cardProof__list-row:last-child {
  margin-bottom: 0;
}

.cardProof__list-row p:nth-child(1) {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #747474;
}

.cardProof__list-row p:nth-child(2) {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #515151;
  margin-left: 10px;
  text-align: right;
}

.cardProof {
  .cardProof__list-row > .cardProof__list-item {
    font-size: 18px;
    font-weight: 500;
  }

  .status-title {
    font-size: 26px;
  }

  .link {
    margin-top: 10px;
  }
}

.link {
  padding: 5px 10px;
  display: block;
  text-align: center;
  color: #3ab2e1;
  text-decoration: underline;
}

.loader {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader svg {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  display: block;
  width: 87px;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.infoScreen {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.infoScreen svg {
  display: block;
  width: 75px;
  height: auto;
  margin-bottom: 20px;
}

.infoScreen p {
  font-weight: 600;
  font-size: 28px;
  line-height: 150%;
  text-align: center;
  color: #515151;
}

.infoScreen .btn {
  margin-top: 30px;
}

.input-box {
  margin-bottom: 20px;
}

.input-box label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #515151;
  padding-bottom: 7px;
  display: flex;
  align-items: center;
}

.input-box label .info {
  position: relative;
  margin-left: 5px;
}

.input-box label .info .symbol {
  font-weight: 700;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  padding-right: 1px;
  background: #ccd5df;
  border-radius: 99px;
}

.input-box label .info .symbol:hover + .window {
  opacity: 1;
  pointer-events: auto;
}

.input-box label .info .window {
  position: absolute;
  left: 50%;
  bottom: calc(100% + 15px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 11px rgba(156, 160, 163, 0.71);
  display: flex;
  align-items: center;
  padding: 13px 10px;
  width: 200px;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.input-box label .info .window:after {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 100%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #fff;
}

.input-box label .info .window p {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #515151;
}

.input-box label .info .window svg {
  display: block;
  width: 48px;
  min-width: 48px;
}

.input-box input {
  display: block;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ccd5df;
  border-radius: 4px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  padding: 9px 12px;
  transition: 0.4s;
}

.input-box input:focus {
  border-color: #515151;
}

.input-box .error-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  color: #ea1717;
  padding-top: 5px;
  display: none;
}

.input-box {
  &.invalid input {
    border-color: #ea1717;
  }
  &.valid input {
    border-color: rgb(0, 255, 0);
  }
}

.input-box.invalid .error-text {
  display: block;
}

.select-box {
  margin-bottom: 20px;
}

.select-box label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #515151;
  padding-bottom: 7px;
  display: flex;
  align-items: center;
}

.select-box label .info {
  position: relative;
  margin-left: 5px;
}

.select-box label .info .symbol {
  font-weight: 700;
  font-size: 9px;
  line-height: 11px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  padding-right: 1px;
  background: #ccd5df;
  border-radius: 99px;
}

.select-box label .info .symbol:hover + .window {
  opacity: 1;
  pointer-events: auto;
}

.select-box label .info .window {
  position: absolute;
  left: 50%;
  bottom: calc(100% + 15px);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 11px rgba(156, 160, 163, 0.71);
  display: flex;
  align-items: center;
  padding: 13px 10px;
  width: 200px;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.select-box label .info .window:after {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 100%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #fff;
}

.select-box label .info .window p {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #515151;
}

.select-box label .info .window svg {
  display: block;
  width: 48px;
  min-width: 48px;
}

.select-box select {
  display: none;
}

.select-box .custom-select {
  position: relative;
  z-index: 2;
}

.select-box .select-selected {
  display: block;
  cursor: pointer;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ccd5df;
  border-radius: 4px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  padding: 9px 12px;
  transition: 0.4s;
  position: relative;
}

.select-box .select-selected:before {
  content: '';
  position: absolute;
  top: 18px;
  right: 18px;
  width: 8px;
  height: 8px;
  border: 2px solid;
  -webkit-transform: translate(50%, -75%) rotate(45deg);
  transform: translate(50%, -75%) rotate(45deg);
  border-left: none;
  border-top: none;
  transition: 0.4s;
}

.select-box .select-selected.select-arrow-active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select-box .select-selected.select-arrow-active:before {
  -webkit-transform: translate(50%, -25%) rotate(-135deg);
  transform: translate(50%, -25%) rotate(-135deg);
}

.select-box .select-items {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background: white;
  border: 1px solid #ccd5df;
  box-shadow: 0px 4px 4px rgba(107, 107, 107, 0.21);
  border-radius: 0 0 4px 4px;
  transition: 0.4s;
}

.select-box .select-items.select-hide {
  opacity: 0;
  pointer-events: none;
}

.select-box .select-items div {
  padding: 10px 12px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #515151;
  transition: 0.4s;
  cursor: pointer;
}

.select-box .select-items div:hover {
  background: #eaedf1;
}

.select-box .error-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  color: #ea1717;
  padding-top: 5px;
  display: none;
}

.select-box.invalid input {
  border-color: #ea1717;
}

.select-box.invalid .error-text {
  display: block;
}

.check-box {
  margin-bottom: 20px;
}

.check-box input {
  display: none;
}

.check-box input:checked + label:after {
  opacity: 1;
}

.check-box label {
  padding-left: 25px;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #a7adb5;
  position: relative;
  display: block;
  cursor: pointer;
}

.check-box label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  background: #ffffff;
  border: 1px solid #ccd5df;
  border-radius: 4px;
}

.check-box label:after {
  content: '';
  position: absolute;
  left: 7.5px;
  top: 7px;
  width: 8px;
  height: 4.5px;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  border: 2px solid #ccd5df;
  border-radius: 1px;
  border-top: none;
  border-right: none;
  opacity: 0;
  transition: 0.4s;
}

.btn {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: block;
  text-align: center;
  color: #ffffff;
  padding: 15px;
  border-radius: 99px;
  transition: 0.4s;
  margin: 0 auto;
  max-width: 280px;
}

.btn-bg {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: block;
  text-align: center;
  color: #ffffff;
  padding: 15px;
  border-radius: 99px;
  transition: 0.4s;
  margin: 0 auto;
  max-width: 280px;
}

.hideThis {
  display: none;
}

body {
  background: #f4f5f9;
}

.header {
  box-shadow: 0px 2px 4px rgba(103, 87, 156, 0.25);
}

.header__logo {
  height: 28px;
}

.header__phone {
  color: #313235;
}

.header__phone:hover {
  border-color: #4bc6f6;
}

.footer {
  background: #313235;
  box-shadow: 0px 0px 8px rgba(103, 87, 156, 0.25);
  &__selfie {
    background: #6eaaca;
  }
  &__credit7 {
    background: #67579c;
  }
  &__slon {
    background: #404a9f;
  }
}

.btn {
  background: #4bc6f6;
  &:hover {
    background: #3ab2e1;
  }

  &__selfie {
    background: #fa9035;
  }
  &__selfie:hover {
    background: #fa9035;
  }

  &__credit7 {
    background: #463874;
  }
  &__credit7:hover {
    background: #463874;
  }

  &__slon {
    background: #33cc66;
  }
  &__slon:hover {
    background: #19ab4a;
  }
}
.btn-bg {
  background: #4bc6f6;
  &:hover {
    background: #3ab2e1;
  }
}

.cardProof .formBottom p a:hover {
  color: #4bc6f6;
}

.select-box .select-selected:before {
  border-color: #4bc6f6;
}

@media screen and (max-width: 1024px) {
  .loader svg {
    width: 67px;
  }

  .infoScreen svg {
    width: 63px;
  }

  .infoScreen p {
    font-size: 21px;
  }

  .header__logo {
    height: 28px;
  }
}

@media screen and (max-width: 950px) {
  .footer .container {
    flex-direction: column;
  }

  .footer__left {
    width: 100%;
    min-width: unset;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(216, 216, 216, 0.08);
  }

  .footer__box:last-child {
    margin-right: 0;
  }

  .footer__box p br,
  .footer__box a br {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .cardProof .container-s {
    padding: 50px calc((90vw - 315px) / 2);
  }
}

@media screen and (max-width: 700px) {
  .popup__scroll {
    max-height: calc(100vh - 250px);
  }
}

@media screen and (max-width: 670px) {
  .footer__ttl {
    margin-bottom: 25px;
  }

  .footer__list {
    flex-direction: column;
  }

  .footer__box {
    margin-bottom: 15px;
  }

  .footer__box:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 500px) {
  .header {
    padding: 10px 0;
  }

  .popup__container {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    padding: 40px 20px 20px;
    display: flex;
    flex-direction: column;
  }

  .popup__title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .popup__shadow {
    padding: 10px;
  }

  .popup__scroll {
    max-height: calc(100vh - 200px);
  }

  .popup .exit-btn {
    top: 10px;
    right: 10px;
  }

  .popup .exit-btn:before {
    width: 18px;
    height: 2px;
  }

  .popup .exit-btn:after {
    width: 18px;
    height: 2px;
  }

  .alert {
    padding: 0;
  }

  .alert .container-s {
    width: 100%;
    border-radius: 0;
  }

  .cardProof {
    padding: 0;
  }

  .cardProof .container-s {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    padding: 40px 20px 30px;
  }

  .cardProof h1 {
    font-size: 16px;
    line-height: 20px;
  }

  .loader svg {
    width: 64px;
  }

  .input-box input {
    padding: 8px 10px;
  }

  .select-box .select-selected {
    padding: 8px 10px;
  }

  .header__logo {
    height: 20px;
  }
}

.button-spin {
  position: relative;
  &__spinner {
    position: absolute;
    top: 15%;
    right: 7%;
    height: 70%;
    & svg {
      height: 100%;
      width: 100%;
    }
    &--c-plus {
      right: 45%;
      & svg {
        fill: rgba(247, 250, 247, 0.6);
      }
      & svg path:first-child {
        display: none;
      }
    }
  }
}

.btn-disabled {
  background-color: gray;
  color: white;
}


.spinner-c-plus {
  & svg {
    fill: rgba(107, 169, 107, 0.6);
  }
  & svg path:first-child {
    display: none;
  }
  &--centred {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.centred-block {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-cotainer {
  display: block;
  width: 70px;
  padding: 5px;
  margin: 0 auto;
  & svg {
    width: 100%;
  }
}

.status-error-list {
  font-size: 18px;
  &__item {
    padding: 5px 0;
    &--only-one {
      white-space: pre-line;
      line-height: 25px;
    }
  }
}
