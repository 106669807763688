.user-agreement {
  color: #596284;
  font-weight: 400;
  font-size: 12px;
  padding: 12px 0 10px 0;
  &__text {
    color: #596284;
    line-height: 15px;
  }
  &__link {
    color: #596284;
    text-decoration: underline;
  }
}
